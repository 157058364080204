@keyframes heartbeat {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(30deg);
  }
  60% {
    transform: rotate(-30deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.hanger-movement {
  animation: heartbeat 1.5s infinite;

  & path {
    fill: #f15f67 !important;
  }
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

.blink {
  animation: blink 1.5s steps(2, start) infinite;
  -webkit-animation: blink 1.5s steps(2, start) infinite;
}
