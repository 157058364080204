.tiles {
  height: 30px;
  width: 20px;
  background-color: #ffffff;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 25px;
  font-weight: 1000;
  margin: 1px;
  animation: flip 1.8s infinite;
}

@keyframes flip {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
